/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import "../components/layout.css"
import Wrapper from "../components/Wrapper"
import Map from "../components/map"
import LandingContact from "../components/LandingContact"
import { StaticImage } from "gatsby-plugin-image"
import { InstagramEmbed } from 'react-social-media-embed';
import { FacebookEmbed } from 'react-social-media-embed';


const Contact = ({ children }) => {

  return (
    <>
<div style={{ display: 'flex', justifyContent: 'center' }}>
  <InstagramEmbed url="https://www.instagram.com/p/Cj2x94bD-IN/" width={328} />
</div>
<div style={{ display: 'flex', justifyContent: 'center' }}>
  <FacebookEmbed url="https://www.facebook.com/photo/?fbid=988593844536716&set=pb.100070645025600.-2207520000." width={550} />
</div>
    </>
  )
}

export default Contact
